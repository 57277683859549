<template>
    <div style="height: auto;margin-bottom: 20px;width: 1200px;margin: 0 auto;padding-bottom: 20px">
        <div class="right_content_parent">
            <div class="right_content">
                <div style="height: auto">
                    <div class="report-title">
            <span>{{ reportList.userName }}</span
            >&nbsp;&nbsp;
                        <span style="margin-left: 20px"
                        >软件企业检测报告
              <span>({{ reportList.createTime }})</span></span
                        >
                    </div>
                    <div style="height:auto; border: 1px solid rgb(218, 226, 229)">
                        <div style="padding-top: 32px" class="div_center">
                            <Row :gutter="42">
                                <Col span="12">
                                    <div style="height: 254px;border:1px solid #BAEBEE">
                                        <Row>
                                            <Col span="12" class="table_div table_div_left">企业基本条件</Col>
                                            <Col span="12" class="table_div table_div_three_right">
                                                <div class="table_div table_div_three_center">
                                                    <span v-if="checkstatus(['placeOfRegistration','enterpriseType','isMbs'])=='符合'" style="color:#26CA83">符合</span>
                                                    <span v-else style="color:#FF6160">不符合</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span="12" class="table_div table_div_left">企业研发能力</Col>
                                            <Col span="12" class="table_div table_div_three_right">
                                                <span v-if="checkstatus(['rdExpenses','cnrdExpenses','numberOfStaff','numberOfRd'])=='符合'" style="color:#26CA83">符合</span>
                                                <span v-else style="color:#FF6160">不符合</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span="12" class="table_div table_div_left">企业业务收入占比</Col>
                                            <Col span="12" class="table_div table_div_three_right">
                                                <span v-if="checkstatus(['salesDevelopment','libertySalesDevelopment'])=='符合'" style="color:#26CA83">符合</span>
                                                <span v-else style="color:#FF6160">不符合</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span="12" class="table_div table_div_left">企业产品和服务</Col>
                                            <Col span="12" class="table_div table_div_three_right">
                                                <span v-if="checkstatus(['isLaw','isAt','isLawoc','isRq','isHasf','isLawoc','isRqp'])=='符合'" style="color:#26CA83">符合</span>
                                                <span v-else style="color:#FF6160">不符合</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span="12" class="table_div table_div_left">企业质量体系</Col>
                                            <Col span="12" class="table_div table_div_three_right">
                                                <span v-if="checkstatus(['iso','cmm','qualification','otherQualification'])=='符合'" style="color:#26CA83">符合</span>
                                                <span v-else style="color:#FF6160">不符合</span>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col span="12">
                                    <div
                                            style="font-size: 14px;height: 254px;border: 1px solid rgb(205, 240, 243);border-right: none;border-right: none;"
                                    >
                                        <Row>
                                            <Col span="6">
                                                <div class="table-box" style="height: 254px;line-height: 254px">
                                                    测评总得分
                                                </div>
                                            </Col>
                                            <Col span="18">
                                                <div class="table-box-double" style="height: 254px;">
                                                    <div style="color: #FDA736;font-size: 14px;margin-top: 70px;font-weight: bold"> 您的企业测评结果：
                                                        <b style="font-size: 32px" v-if="reportList.evalScore">符合</b>
                                                        <b style="font-size: 32px;color: #FF6160" v-else>不符合</b>
                                                    </div>
                                                    <div style="height: 42px;background: #F3F9FC;border-radius: 2px;
                                                    text-align: center;line-height: 42px;font-size: 12px;margin-top: 20px;border-bottom: ">
                                                        <div v-if="!reportList.evalResult ">
                                                            <Icon type="md-close-circle" color="#FF6160"/>
                                                            很遗憾，企业不符合软件企业申报标准
                                                        </div>
                                                        <div v-else>
                                                            <Icon type="md-checkmark-circle" color="#36C2CF"/>
                                                            恭喜，企业各项指标数据良好，符合软件企业申报标准
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div style="padding: 50px 0" class="div_center">
                            <div style="font-size: 14px;border: 1px solid rgb(205, 240, 243);border-right: none;">
                                <Row>
                                    <Col span="5" class="table_div" style="background-color: #1767E0;color: white;">项目</Col>
                                    <Col span="7" class="table_div table_div_five_center">项目内容</Col>
                                    <Col span="4" class="table_div table_div_five_center">企业情况</Col>
                                    <Col span="4" class="table_div table_div_five_center">认定标准</Col>
                                    <Col span="4" class="table_div table_div_five_center">结果</Col>
                                </Row>
                                <Row>
                                    <Col span="5" class="table_div_three_left yi_hang_sheng">
                                        基本条件
                                    </Col>
                                    <Col span="7">
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            企业注册地
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            企业类型
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            主营业务范围：软件开发生产、系统集成、应用服务和其他相应技术服务
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.placeOfRegistration)[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.enterpriseType)[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isMbs)[1]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.placeOfRegistration)[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.enterpriseType )[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isMbs)[2]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.placeOfRegistration)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.enterpriseType)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.isMbs)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="5" class="table_div_three_left">
                                        研发能力
                                    </Col>
                                    <Col span="7">
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            总研发占比
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            中国境内研发占比
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            大专以上职工占比
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            大专以上研发人员占比
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.rdExpenses)[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.cnrdExpenses)[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.numberOfStaff)[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.numberOfRd )[1]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.rdExpenses)[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.cnrdExpenses )[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.numberOfStaff)[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.numberOfRd )[2]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.rdExpenses)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.cnrdExpenses)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.numberOfStaff)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.numberOfRd)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="5" class="table_div_three_left">
                                        业务收入占比
                                    </Col>
                                    <Col span="7">
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            {{reportList.length?selectModal[reportList.salesRevenueType-1]['label']:''}}
                                            占比
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            自主软件产品开发销售（营业）收入占比
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.salesDevelopment)[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.libertySalesDevelopment)[1]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.salesDevelopment)[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.libertySalesDevelopment)[2]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.salesDevelopment)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.libertySalesDevelopment)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="5" class="table_div_three_left">
                                        软件产品
                                    </Col>
                                    <Col span="7">
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            是否具有该产品的知识产权
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            是否经软件检测机构检测合格，并出具合格报告
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            是否符合法律、行政法规规定的其他条件
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isLaw)[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isAt )[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isLawoc )[1]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isLaw)[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isAt )[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isLawoc)[2]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.isLaw)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.isAt)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.isLawoc)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="5" class="table_div_three_left">
                                        信息技术服务
                                    </Col>
                                    <Col span="7">
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            是否有承接信息技术服务的相关资质或标准
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            是否具备提供技术服务的软硬件设施
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            是否有承接信息技术服务的相关资质人员
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            是否符合法律、行政法规规定的其他条件
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isRq)[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isHasf)[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isLawoc )[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isRqp)[1]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isRq)[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isHasf)[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isLawoc)[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.isRqp)[2]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.isRq)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.isHasf)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.isLawoc)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.isRqp)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="5" class="table_div_three_left">
                                        质量体系
                                    </Col>
                                    <Col span="7">
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            ISO9000系列质量管理系统证书
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            CMM/CMMI质量管理体系证书
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            系统集成企业资质
                                        </div>
                                        <div class="table_div table_div_three_center yi_hang_sheng">
                                            其他资质认定
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.iso)[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.cmm)[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.qualification)[1]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.otherQualification)[1]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.iso)[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.cmm)[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.qualification)[2]}}
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            {{datasplit(reportList.otherQualification)[2]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.iso)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.cmm)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.qualification)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                        <div class="table_div table_div_three_center">
                                            <span v-if="datasplit(reportList.otherQualification)[3]=='符合'" style="color:#26CA83">符合</span>
                                            <span v-else style="color:#FF6160">不符合</span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import {examUrl} from "../plugins/http/baseUrl";

    export default {
        name: "examSoftReport",
        mounted() {
            // this.getReportFun()
            this.id = this.$route.query.id
            this.getReportFun()
        },
        computed: {},
        data() {
            return {
                transfer: true,
                reportList: [],
                id: 0
            };
        },
        methods: {
            // 得到报告
            async getReportFun() {
                let param = {
                    id: this.id,
                    status: '1',
                    userName: ''
                }
                let url = `${examUrl}/rjqyee/list?pageSize=1&pageNum=1`
                axios.post(url, param).then(res => {
                    let json = res.data
                    console.log('json', json)
                    if (json.code == 200 && json.data.list && json.data.list.length > 0) {
                        this.reportList = json.data.list[0]
                        this.reportList.createTime = this.reportList.createTime.split('T')[0]
                    }
                })

            },
            datasplit(arr) {
                if (arr) {
                    return arr.split(';')
                } else {
                    return ''
                }
            },
            checkstatus(obj){
                for(let m of obj){
                    if(this.datasplit(this.reportList[m])[3] != '符合'){
                        return '不符合'
                    }
                }
                return '符合'
            }
        },
    };
</script>

<style scoped lang="scss">
    /deep/ .ivu-input:focus {
        border: 1px solid rgba(205, 240, 243, 1) !important;
        box-shadow: none !important;
    }

    .ivu-input:focus {
        outline: none !important;
    }

    .yi_hang_sheng {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
    }

    /deep/ .ivu-input-wrapper {
        vertical-align: baseline !important;
    }

    /deep/ .table_div .ivu-input {
        height: 50px !important;
        text-align: center !important;
        padding: 0;
        border: none !important;
    }

    /deep/ .table_div .ivu-date-picker-rel .ivu-input {
        height: 40px !important;
        text-align: center !important;
        padding: 0 0;
        border: 1px solid #dcdee2 !important;

    }

    .first:last-child .ivu-input {
        border-bottom: 1px solid rgba(205, 240, 243, 1);
    }

    /deep/ .table_div2 .ivu-input {
        height: 36px !important;
        text-align: center !important;
        background: #FFFFFF;
        border-radius: 1px;
        border: 1px solid #CCCCCC;
        padding: 0;
    }

    /deep/ .ivu-select {
        width: 50% !important;
    }

    /deep/ .ivu-input-wrapper {
        vertical-align: center !important;
    }

    /deep/ .ivu-steps-item.ivu-steps-status-finish .ivu-steps-tail > i:after {
        background-color: #1767E0 !important;
    }

    /deep/ .ivu-steps-item.ivu-steps-status-finish .ivu-steps-head-inner {
        border-color: #1767E0 !important;
    }

    /deep/ .ivu-steps-item.ivu-steps-status-finish .ivu-steps-head-inner span,
    .ivu-steps-item.ivu-steps-status-finish
    .ivu-steps-head-inner
    > .ivu-steps-icon {
        color: #1767E0 !important;
    }

    .table_div {
        height: 50px;
        text-align: center;
        line-height: 50px;
        width: 100%;
    }

    /deep/ .ivu-select-selection {
        border-radius: 0;
    }

    .table_div2 {
        height: 50px;
        line-height: 50px;
        width: 100%;
        padding: 0 10%;
        display: flex;
        align-items: center;
    }

    .table_div_left {
        border-right: 1px solid #BAEBEE;
        background-color: rgba(205, 240, 243, 0.3);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .table_div_three_center {
        border-right: 1px solid #BAEBEE;
        border-bottom: 1px solid #BAEBEE;
        border-left: 1px solid #BAEBEE;
    }

    .table_div_three_center:last-child {
        border-bottom: none;
    }

    .table_div_three_right {
        border-bottom: 1px solid #BAEBEE;
    }

    .table_div_three_right:last-child {
        border-bottom: none;
    }

    .table_div_three_left {
        background: rgba(205, 240, 243, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .table_div_five_center{
        border-right: 1px solid #BAEBEE;
    }

    /deep/ .ivu-steps-item.ivu-steps-status-process .ivu-steps-head-inner {
        background: #1767E0;
        border: #1767E0;
    }

    /deep/ .ivu-steps-head-inner span {
    }

    /deep/ .ivu-steps .ivu-steps-title {
        display: block;
        margin-top: 25px;
    }

    /deep/ .ivu-table-cell {
        text-align: center !important;

        & > span {
            text-align: center !important;
        }
    }

    /deep/ .ivu-table th {
        height: 50px !important;
        text-align: center;
    }

    .ivu-table-row {
        height: 50px !important;
    }

    /deep/ .ivu-table td {
        height: 50px !important;
    }

    /deep/ .ivu-radio-inner:after {
        background-color: #1767E0;
    }

    .table-box {
        width: 100%;
        height: 55px;
        background-color: #f3fcfc;
        line-height: 55px;
        text-align: left;
        border-bottom: 1px solid rgba(205, 240, 243, 1);
        border-right: 1px solid rgb(205, 240, 243);
        padding: 0 10px;
        overflow: hidden;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .ivu-progress {
        display: inline-block !important;
        width: 65% !important;
    }

    .common_liucheng:nth-child(1) {
        border: none;
    }

    .common_liucheng {
        border-top: 2px dotted #f5f5f5;
        width: 95%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        & > span {
            display: block;
            padding-left: 5px;
            padding-top: 38px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            color: rgb(51, 51, 51);
            margin-bottom: 60px;
        }
    }

    .next_style {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .button_style {
        display: inline-block;
        background-color: #1767E0;
        z-index: 51;
        text-align: center;
        font-size: 14px;
        color: white;
        cursor: pointer;
        padding: 0 27px;
        line-height: 32px;
        height: 32px;
        border-radius: 4px;
    }

    .retest-btn {
        height: 40px;
        width: 120px;
        margin-left: 20px;
        background-color: #1767E0;
    }

    .isChoose {
        color: #37c5cf;
    }

    .right_content {
        width: 90%;
        /*border-width: 1px;*/
        /*border-color: rgb(218, 226, 229);*/
        /*border-style: solid;*/
        background-color: rgb(255, 255, 255);
        min-width: 900px;
        height: auto;
    }

    .right_content_parent {
        box-sizing: border-box;
        width: 100%;
        min-width: 960px;
        float: left;
        display: flex;
        justify-content: center;
        padding-top: 50px;
        position: relative;
        margin-bottom: 130px;
    }

    .left_tab_parent {
        float: left;
        min-width: 240px;
        width: 20%;
        background-color: rgb(250, 250, 252);

        .left_tab {
            margin-top: 60px;

            div {
                width: 210px;
                margin: 0 auto;
                height: 48px;
                line-height: 48px;
                font-size: 14px;
                box-sizing: border-box;
                padding-left: 31px;
                margin-bottom: 20px;
                border-width: 1px;
                border-color: rgb(235, 235, 235);
                border-style: solid;
                background-color: rgb(255, 255, 255);
                cursor: pointer;
            }
        }
    }

    /deep/ .demo-table-info-column {
        background-color: #f3fcfc;
        color: white;
    }

    /deep/ .ivu-table-tbody .demo-table-info-column {
        color: black !important;
    }

    .hidden_span {
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
        white-space: pre-wrap;
        line-height: normal;
        margin-left: 10px;
        color: #333333;
    }

    .step-item {
        height: 100px;
        margin: 60px 0 20px 50%;
        width: 80%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
    }

    .step-bg {
        width: 90%;
        margin-left: 50%;
        transform: translateX(-50%);
        border: 1px solid #BAEBEE;
    }

    .step-title {
        width: 90%;
        transform: translateX(-50%);
        font-size: 16px;
        color: #333333;
        margin: 24px 0 24px 50%;
        font-weight: bold;
    }

    .ivu-row {
        border-bottom: 1px solid #BAEBEE;
    }

    .ivu-row:last-child {
        border-bottom: none;
    }

    .ivu-radio-wrapper {
        width: 110px !important;
        text-align: left;
    }

    .table-box {
        width: 100%;
        height: 55px;
        background-color: #f3fcfc;
        line-height: 55px;
        text-align: center;
        border-bottom: 1px solid rgba(205, 240, 243, 1);
        border-right: 1px solid rgb(205, 240, 243);
        padding: 0 10px;
        overflow: hidden;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .table-box2 {
        width: 100%;
        height: 55px;
        line-height: 55px;
        text-align: left;
        border-bottom: 1px solid rgba(205, 240, 243, 1);
        border-right: 1px solid rgb(205, 240, 243);
        font-size: 14px;
    }

    .table-box-double {
        width: 100%;
        height: 55px;
        line-height: 55px;
        text-align: center;
        /*border-bottom: 1px solid rgb(205, 240, 243);*/
        border-right: 1px solid rgb(205, 240, 243);
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 10px;
    }

    .report-title{
        display: flex;
        justify-content: center;
        height: 70px;
        line-height: 70px;
        background-color: #1767E0;
        border: 1px solid #1767E0;
        color: white;
        font-size: 20px;
    }
    .div_center {
        width: 95%;
        margin-left: 50%;
        transform: translateX(-50%);
        /*border-bottom: 2px dotted rgb(218, 226, 229);*/
    }
</style>
